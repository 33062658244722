<template>
  <div class="step4 apply">
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>리워드 상품정보</span>
        </div>
        <a class="color-point font-sm pointer" @click="openExample('productNotice', '상품정보고시')">상품 별 예시보기</a>
      </div>
      <div class="content">
        <div class="wrapper">
          <div class="subject font-sm">
            <span>상품정보고시</span>
          </div>
          <SimpleTinyEditor :id="`${component.name}ProductNotice`" :value.sync="form.new.productNotice" ref="editorRef" :key="editorKey" :height="350" :placeholder="getContent(-1)" page="apply"/>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>프로젝트 정책</span>
        </div>
      </div>
      <div class="content">
        <div class="wrapper" v-for="(item, idx) in form.new.addInfoItems" :key="idx">
          <div class="subject font-sm">
            <span class="color-point">*</span>
            <span> {{ item.title }}</span>
          </div>
          <textarea :id="`${component.name}ItemContent${idx}`" class="form-control border-focus-point font-sm" maxlength="500" v-model="item.content" placeholder="프로젝트 정책을 작성해주세요"/>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>증명 서류</span>
        </div>
        <a class="color-point font-sm pointer" @click="openExample('proofDocuments', '증명 서류')">제출 서류 확인하기</a>
      </div>
      <div class="content">
        <div class="wrapper">
          <div class="d-flex justify-content-between">
            <label class="subject float-left mb-0" @click="setFiles()">
              <span>통신판매업신고증 및 기타 서류 첨부</span>
              <span class="font-xs"> ({{ files.new.productFiles.length }}/{{ maxProductFilesCnt }})</span>
            </label>
            <a class="color-point pointer font-sm" @click="setFiles()" v-if="files.new.productFiles.length < maxProductFilesCnt">파일 업로드</a>
          </div>
          <ul class="files tight" v-if="files.new.productFiles.length > 0">
            <li class="font-sm pointer" data-type="image" v-for="(f, idx) in files.new.productFiles" :key="idx" @click="$store.dispatch('previewFile', f)" title="클릭 시 미리보기">
              <div>{{ f.fileSeq ? f.fileOrigName : f.name }}</div>
              <span class="pointer times" title="삭제" @click.stop="delProductFile(idx)">&times;</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import TinyEditor from "@/components/TinyEditor";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number.vue";
import Date from "@/components/Date.vue";
import store from "@/scripts/store";
import SimpleTinyEditor from "@/components/SimpleTinyEditor.vue";
import Phone from "@/components/Phone.vue";

function Component(initialize) {
  this.name = "pageApplyNewRewardMakerStep4";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
    keys: Object,
  },
  components: {Phone, SimpleTinyEditor, Date, Number, TinyEditor},
  setup(props) {
    const component = new Component(() => {
      props.validators[4] = () => {
        for (let i in props.form.new.addInfoItems) {
          if (!props.form.new.addInfoItems[i].content?.trim()) {
            return props.warn("내용을 입력해주세요.", `${component.name}ItemContent${i}`);
          }
        }

        return true;
      };

      for (let i = 0; i < props.form.new.addInfoItems.length; i += 1) {
        if (!props.form.new.addInfoItems[i].content || !props.form.new.addInfoItems[i].content?.trim()) {
          props.form.new.addInfoItems[i].content = getContent(i);
        }
      }
    });

    const state = reactive({
      agree1: false,
      agree2: false,
      agree3: false,
      categories: [],
      accordion: false,
    });

    const getContent = (idx) => {
      if (idx === -1) {
        return "식품 상품정보고시 예)"
            + "\n1-1. 제품명:"
            + "\n1-2. 식품의 유형:"
            + "\n1-3. 생산자 및 소재지 (수입품의 경우 생산자, 수입자 및 제조국):"
            + "\n1-4. 제조연원일, 소비기한 또는 품질유지기한:"
            + "\n1-5. 포장단위별 내용물의 용량(중량), 수량:"
            + "\n1-6. 원재료명:"
            + "\n1-7. 영양성분:"
            + "\n1-8. 유전자변형식품에 해당하는 경우의 표시:"
            + "\n1-9. 소비자 안전을 위한 주의사항:"
            + "\n2. 수입식품의 경우 “수입식품안전관리 특별법에 따른 수입신고를 필함“의 문구"
            + "\n3. 소비자 상담 관련 전화번호";
      } else if (idx === 0) {
        return "- 리워드 발송이 늦어지게 된다면 따로 안내해 드릴 예정이며 혹시나 문자를 받지 못하신 경우, 문의처로 연락해 주시기 바랍니다."
            + "\n- 리워드 배송에 대한 문의가 있을 시 프로젝트 상단 'Q&A'에 질문을 등록해 주시기 바랍니다.";
      } else if (idx === 1) {
        return "- 펀딩해 주신 후원자분들께 약속드린 리워드 발송일을 지키기 위해 최선을 다할 것입니다. 예상치 못한 상황으로 인해 리워드 발송이 다소 지연될 수 있는 점을 알려드립니다."
            + "\n- 약속된 날로부터 리워드 제공 지연이 예상되는 즉시, 상세한 사유와 변경되는 발송일을 알려드리겠습니다.";
      } else if (idx === 2) {
        return "- 환불은 펀딩종료일 1일 전까지 가능하며, 펀딩 종료일 당일 및 펀딩 마감 이후에는 환불이 불가능합니다. 또한 펀딩 기간에 리워드가 배송된 경우에도 환불이 불가능하오니 이 점 참고 부탁드립니다."
            + "\n- 펀딩 종료일 이후 단순 변심으로 인한 환불 및 교환은 불가능합니다."
            + "\n- 환불 및 교환 관련 문의가 있을 시 프로젝트 상단 'Q&A'에 질문을 등록해 주시기 바랍니다."
            + "\n- 지속적인 리워드 배송 지연 및 진행자의 개인적인 사유로 리워드 제공이 이루어지지 않는 경우 진행자가 직접 후원자의 환불 계좌를 받아 참여 금액의 100%를 환불해 드리겠습니다.";
      }
    };

    const maxProductFilesCnt = 10;
    const editorRef = ref();
    const editorKey = 1;

    const setFiles = () => {
      const isAllowedCnt = (filesCnt = 1) => {
        return props.files.new.productFiles.length + filesCnt <= maxProductFilesCnt;
      };

      if (!isAllowedCnt()) {
        store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${maxProductFilesCnt}개)를 초과하였습니다.`);
        return;
      }

      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files.new.productFiles`,
          maxCnt: maxProductFilesCnt,
          maxCntLabel: maxProductFilesCnt,
        },
      });
    };

    const delProductFile = (idx) => {
      if (props.files.new.productFiles[idx].fileCode) {
        props.files.deletes.push(props.files.new.productFiles[idx]);
      }

      props.files.new.productFiles.splice(idx, 1);
    };

    const openExample = (sectionName, sectionTitle) => {
      store.commit("openModal", {
        name: "RewardApplyExamples",
        params: {
          invisibleExample: true,
          sectionName,
          sectionTitle,
        }
      });
    };

    return {
      component,
      state,
      maxProductFilesCnt,
      editorRef,
      editorKey,
      getContent,
      setFiles,
      delProductFile,
      openExample,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step4 {
  display: flex;
  flex-direction: column;
  gap: $px70;

  .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px24;

      textarea {
        min-height: $px150;

        &.notice {
          height: $px300;
        }
      }
    }
  }

  .form-group {
    .accordion {
      padding: $px15 0;

      button {
        &:focus {
          position: relative;
          box-shadow: none;
          z-index: 1;
        }

        i {
          margin-left: $px5;
          transition: transform 0.18s, margin 0.18s;
        }

        &:not(.collapsed) {
          font-weight: 500;

          i {
            margin-top: $px1;
            transform: rotate(180deg);
          }
        }
      }

      .content {
        word-break: keep-all;
      }

      &:last-child {
        border-bottom-width: $px1;
      }
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;
      gap: $px16;

      > .content {
        > .row {
          gap: $px16;
        }
      }
    }
  }
}
</style>