<!-- #517 꺽쇠 이슈로 - TinyEditor를 추가하는 경우 filter-excepts-replace.json 예외 추가 필요 -->
<template>
  <textarea :id="id" class="tiny-editor border-focus-point form-control" :class="page" v-html="computedValue" ref="textareaRef"></textarea>
</template>

<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref,} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentSimpleTinyEditor";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    value: String,
    height: Number,
    disabled: Boolean,
    maxLength: Number,
    placeholder: String,
    page: String,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({
      instance: null
    });

    const textareaRef = ref();

    const computedValue = computed(() => {
      if (props.value) {
        return props.value.split("&lt;").join("&amp;lt;").split("&gt;").join("&amp;gt;");
      }

      return props.value;
    });

    const getTinymceConfig = (options) => {
      const contentCss = options.contentCss;
      const widthRate = options.widthRate;
      const mode = options.mode;
      const fullscreen = options.fullscreen;

      const config = {
        target: textareaRef.value,
        content_css: contentCss,
        entity_encoding: "raw",
        plugins: [
          "advlist lists charmap print preview anchor pagebreak",
          "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
          "directionality template paste textpattern table"
        ],
        language: "ko_KR",
        language_url: "/assets/lib/tinymce/custom/langs/ko_KR.js",
        toolbar: "undo redo bold | media | table",
        toolbar_sticky: true,
        toolbar_sticky_offset: 0,
        toolbar_mode: "wrap",
        body_class: "edit-style editor simple-editor",
        extended_valid_elements: "area[shape|coords|href|target|onfocus]",
        forced_root_block: "p",
        statusbar: false,
        menubar: "false",
        height: props.height || 1000,
        automatic_uploads: true,
        paste_as_text: true,
        relative_urls: false,
        // placeholder: props.placeholder,
        media_dimensions: false,
        table_default_attributes: {
          border: "0"
        },
        table_default_styles: {
          "border-collapse": "collapse",
          "width": "100%",
          "border-width": "2px 0",
          "border-style": "solid",
          "border-color": "#48494a"
        },
        setup: function (editor) {
          editor.on("input undo redo paste change keyup", () => {
            if (!props.maxLength || window.tinymce.activeEditor.plugins.wordcount.body.getCharacterCount() <= props.maxLength) {
              emit("update:value", editor.getContent());
            } else if (window.tinymce.activeEditor.plugins.wordcount.body.getCharacterCount() > props.maxLength) {
              editor.setContent(props.value);
              return store.commit("setSwingMessage", `${props.maxLength}자 이하로 입력해주세요`);
            }
          });

          editor.on("init", function () {
            let newLang = {"Caption": "이미지 설명", "Show caption": "이미지 설명 추가"};
            window.tinymce.addI18n("ko_KR", newLang);

            if (!editor.getContent()) {
              editor.getBody().setAttribute("data-placeholder", props.placeholder);
            }
          });

          editor.on("focus", function () {
            editor.getBody().removeAttribute("data-placeholder");
          });

          editor.on("blur", function () {
            if (editor.getContent() === "") {
              editor.getBody().setAttribute("data-placeholder", props.placeholder);
            }
          });

          editor.on("focusout", function () {
            if (((navigator.userAgent.toLowerCase()).indexOf("chrome") !== -1) && (window.tinymce.activeEditor.plugins.fullscreen.isFullscreen())) {
              window.tinymce.activeEditor.focus();
            }
          });
        },
      };

      if (widthRate != null) {
        config.width = widthRate;
      }

      if (mode) {
        delete config.selector;
        config.mode = "textareas";
      }

      if (fullscreen) {
        config.toolbar = "fullscreen | " + config.toolbar;
      }

      return config;
    };

    onBeforeUnmount(() => {
      window.tinymce.remove(state.instance);
    });

    onMounted(() => {
      store.dispatch("appendScript", {
        src: "/assets/lib/tinymce/tinymce.min.js",
        onEveryLoad() {
          window.tinymce.init(getTinymceConfig({
            contentCss: ["/assets/lib/font-awesome.min.css", "/assets/lib/tinymce/custom/css/edit.css", "/assets/lib/tinymce/custom/css/rewardEdit.css"],
            fullscreen: true
          })).then((instances) => {
            state.instance = instances[0];

            if (props.disabled) {
              state.instance.setMode("readonly"); // 에디터를 읽기 전용으로 설정
            } else {
              state.instance.setMode("design"); // 에디터를 활성화
            }
          });
        }
      });
    });

    return {component, state, textareaRef, computedValue};
  }
});
</script>

<style lang="scss">
.tox {
}
</style>